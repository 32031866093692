<template>
    <div class="div">
        <b-img v-if="!options" :src="this.$loaderImage" height="20" />

        <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left" v-if="options">
            <!-- Plan Comptable -->
            <b-tab active>
                <template #title>
                    <feather-icon icon="SlidersIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold"> Plan Comptable </span>
                </template>

                <accounting-plan />
            </b-tab>

            <!-- Plan Analytique -->
            <b-tab>
                <template #title>
                    <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">Plan Analytique</span>
                </template>

                <analytic-plan />
            </b-tab>

            <!-- Codes Journaux -->
            <b-tab>
                <template #title>
                    <feather-icon icon="ListIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">Codes Journaux</span>
                </template>

                <daily-book v-if="options.dailybook" />
            </b-tab>

            <b-tab>
                <!-- Bilan -->
                <template #title>
                    <feather-icon icon="ToolIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">Paramétrer Bilan</span>
                </template>

                <accounting-plan v-if="options.balancesheet" />
            </b-tab>
        </b-tabs>
    </div>
</template>
  
<script>
import { BTabs, BTab, BImg } from "bootstrap-vue";
import AccountingPlan from "./accountingplan/Index.vue";
import AnalyticPlan from "./analyticplan/Index.vue";

export default {
    components: {
        BTabs,
        BTab,
        BImg,
        AccountingPlan,
        AnalyticPlan,
    },
    data() {
        return {
            options: {},
        };
    },
    async beforeCreate() {

        var cData = {
            accountingplan: {
                "title": "Plan Comptable"
            },
            analyticplan: {
                "title": "Plan Analytique"
            },
            dailybook: {
                "title": "Journaux"
            },
            balancesheet: {
                "title": "Bilan"
            },
        };

        this.options = cData;

    },
};
</script>
  